import { FC, useEffect, useState } from "react";

import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";

import FullPageLoader from "../../../../../../common/components/item/FullPageLoader";
import { useUpdateUser } from "../../../../../../common/hooks/useUpdateUser";
import {
  errorNotification,
  successNotification,
} from "../../../../../../common/variables/notification";
import {
  DEFAULT_VIDEO_SPEED,
  IParsedUserInfo,
} from "../../../../../../leak-finder/hooks/useLazyGetUser";
import UserPreferencesSection from "../UserPreferencesSection";
import VideoSpeedOption from "./VideoSpeedOption";
import { UserSettingsInput } from "../../../../../../API";

export interface IVideoSpeedOption {
  value: number;
  label: string;
}

export const videoSpeedOptions: IVideoSpeedOption[] = [
  {
    value: 1,
    label: "1x",
  },
  {
    value: 2,
    label: "2x",
  },
  {
    value: 5,
    label: "5x",
  },
];

interface IVideoSpeedSectionProps {
  userSettings: IParsedUserInfo | null;
}

const VideoSpeedSection: FC<IVideoSpeedSectionProps> = ({ userSettings }) => {
  const { loading: updateUserLoading, updateUser } = useUpdateUser();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedSpeed, setSelectedSpeed] = useState<IVideoSpeedOption>(
    videoSpeedOptions[1]
  );

  useEffect(() => {
    const defaultSpeedOption = videoSpeedOptions.find(
      option => option.value === userSettings?.videoSpeed ?? DEFAULT_VIDEO_SPEED
    );

    setSelectedSpeed(defaultSpeedOption ?? videoSpeedOptions[1]);
  }, [userSettings]);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);

    setOpen(previousOpen => !previousOpen);
  };

  const handleSelectedSpeedChange = (speed: IVideoSpeedOption) => {
    setSelectedSpeed(speed);

    const newSettings: UserSettingsInput = {
      defaultCameraFov: userSettings?.cameraFov as number,
      defaultWindspeedUnit: userSettings?.windspeedUnit as string,
      defaultVideoSpeed: speed.value,
    };

    updateUser(
      userSettings?.userId as string,
      userSettings?.userRole as string,
      null,
      newSettings
    )
      .then((response): void => {
        if (response?.data?.updateUser) {
          successNotification("User preferences updated successfully");

          setOpen(false);
        }
      })
      .catch(error => {
        errorNotification(error.message);
      });
  };

  if (updateUserLoading) {
    return <FullPageLoader />;
  }

  return (
    <UserPreferencesSection title="Video speed">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="body2Regular">Set default video speed</Typography>

        <Button
          disableRipple
          sx={{
            ml: "auto",
            padding: 0,
            height: "fit-content",
            color: theme => theme.palette.text.primary,
          }}
          variant="text"
          endIcon={open ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
          onClick={handleButtonClick}
        >
          <Typography variant="body2Regular">{selectedSpeed.label}</Typography>
        </Button>

        <Popper open={open} anchorEl={anchorEl} transition placement="right">
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                sx={{
                  bgcolor: theme => theme.palette.otherBackgroundLight.main,
                }}
              >
                <List>
                  {videoSpeedOptions.map(option => {
                    return (
                      <VideoSpeedOption
                        key={option.label}
                        label={option.label}
                        value={option.value}
                        active={option.value === selectedSpeed.value}
                        onSelectedSpeedChange={handleSelectedSpeedChange}
                      />
                    );
                  })}
                </List>
              </Paper>
            </Fade>
          )}
        </Popper>
      </Box>
    </UserPreferencesSection>
  );
};

export default VideoSpeedSection;
